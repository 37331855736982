import React                             from 'react';
import { graphql }                       from 'gatsby';
import Img                               from 'gatsby-image';
import { Button, Center, useMediaQuery } from '@chakra-ui/react';
import { FaRulerCombined }               from 'react-icons/fa';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import NavExplorer   from '@interness/theme-sonora/src/components/NavExplorer';
import { findMedia } from '@interness/web-core/src/components/_helpers';
import Mounted       from '@interness/web-core/src/components/structure/Mounted/Mounted';
import MoreExpander  from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Link          from '@interness/web-core/src/components/elements/Link/Link';

import InfoBlocks from '../components/InfoBlocks';
import Carousel   from '@interness/web-core/src/components/media/Carousel/Carousel';
import SEO        from '@interness/web-core/src/components/modules/SEO/SEO';

function IndexPage({ data }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const nav = [
    {
      title: 'Nischen',
      image: findMedia(data.navExplorer.media, 'nische_1'),
      linkTo: '/nischen'
    },
    {
      title: 'Schrägen',
      image: findMedia(data.navExplorer.media, 'schraege_2'),
      linkTo: '/schragen'
    },
    {
      title: 'Garderoben',
      image: findMedia(data.navExplorer.media, 'garderobe_1'),
      linkTo: '/garderoben'
    },
    {
      title: 'Schlafzimmer',
      image: findMedia(data.navExplorer.media, 'schlafzimmer_1'),
      linkTo: '/schlafzimmer'
    },
    {
      title: 'Schiebetüren',
      image: findMedia(data.navExplorer.media, 'schiebetur_1'),
      linkTo: '/schiebeturen'
    },
    {
      title: 'Kleiderzimmer',
      image: findMedia(data.navExplorer.media, 'kleiderzimmer_1'),
      linkTo: '/kleiderzimmer'
    },
  ]
  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {data[isMobile ? 'headerImagesMobile' : 'headerImages'].images.map((image) => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Center>
          <Button mt="-20px" variant="outline" colorScheme="brand" backgroundColor="white"
                  sx={{ textDecoration: 'none' }} as={Link} to="/kontakt">Zum
            kostenfreien Beratungstermin</Button>
        </Center>
        <Spacer/>
        <Heading icon={<FaRulerCombined/>} subtitle={'In ganz NRW für Sie vor Ort. Gut, passend, preiswert.'}>Ihr
          Traumschrank nach
          Maß</Heading>
        <p>Wenn es um individuelle Schrankeinbauten geht, kann es manchmal schwierig sein, im Möbelhaus eine passende
          Lösung zu finden. Standardmöbelstücke aus dem Handel sind in der Regel auf eine breite Kundennachfrage
          zugeschnitten und bieten begrenzte Anpassungsmöglichkeiten. In solchen Fällen sind wir für Sie da und bieten
          Ihnen maßgefertigte Lösungen</p>
        <MoreExpander>
          <p>Ein individueller Schrankeinbau bietet eine Vielzahl von Vorteilen. Er kann den verfügbaren Raum optimal
            nutzen und sich nahtlos in die vorhandene Einrichtung einfügen. Durch die individuelle Gestaltung können
            spezifische Anforderungen und Bedürfnisse berücksichtigt werden, sei es zusätzlicher Stauraum, eine
            spezielle Aufteilung der Fächer oder eine besondere ästhetische Gestaltung.</p>
          <p>Der Prozess eines individuellen Schrankeinbaus beginnt mit einer genauen Bestandsaufnahme des Raums und
            einer detaillierten Besprechung der Anforderungen des Kunden. Anhand dieser Informationen erstellen wir ein
            maßgeschneidertes Design, das Ihren individuellen Bedürfnissen gerecht wird. Dabei werden verschiedene
            Faktoren wie Materialien, Farben, Größen und Funktionen berücksichtigt.</p>
          <p>Nach der Planungsphase erfolgt die Fertigung des Schrankes in unseren Werkstätten. Hier kommen hochwertige
            Materialien und handwerkliche Präzision zum Einsatz, um ein qualitativ hochwertiges Endprodukt zu
            gewährleisten. Anschließend wird der individuelle Schrankeinbau vor Ort installiert, wobei eine sorgfältige
            Montage und eine genaue Anpassung an den Raum erfolgen.</p>
          <p>Der Vorteil eines individuellen Schrankeinbaus liegt darin, dass er eine einzigartige Lösung bietet, die
            genau auf die Bedürfnisse Ihre zugeschnitten ist. Es entsteht ein maßgeschneidertes Möbelstück, das nicht
            nur funktional ist, sondern auch ästhetisch ansprechend wirkt und den Raum optimal nutzt. Daher kann ein
            individueller Schrankeinbau eine lohnende Investition sein, um eine maßgeschneiderte Lösung zu erhalten, die
            den eigenen Vorstellungen und Anforderungen entspricht.</p>
        </MoreExpander>
        <Spacer/>
        <Mounted>
          <Wrapper width={1200}>
            <NavExplorer nav={nav}/>
          </Wrapper>
        </Mounted>
        <Spacer/>
      </Wrapper>
      <Center>
        <Button variant="outline" colorScheme="brand" backgroundColor="white" sx={{ textDecoration: 'none' }} as={Link}
                to="/kontakt">Zum
          kostenfreien Beratungstermin</Button>
      </Center>
      <Wrapper>
        <Spacer/>
        <InfoBlocks/>
        <Spacer/>
      </Wrapper>
      <Center>
        <Button variant="outline" colorScheme="brand" backgroundColor="white" sx={{ textDecoration: 'none' }} as={Link}
                to="/kontakt">Zum
          kostenfreien Beratungstermin</Button>
      </Center>
      <Spacer/>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query IndexQuery {
        headerImages: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImagesMobile: directusMediaCollection(name: {eq: "home-mobile"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        navExplorer: directusMediaCollection(name: {eq: "keep-exploring-nav"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;