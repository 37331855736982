import React         from 'react';
import Img           from 'gatsby-image';
import { Box, Flex } from '@chakra-ui/react';

import Link from '@interness/web-core/src/components/elements/Link/Link';

const NavExplorer = ({ nav }) => {
  return (
    <Flex flexWrap={'wrap'} justifyContent={'space-between'} sx={{
      a: {
        color: 'unset',
        textDecoration: 'unset'
      }
    }}>
      {nav.map(obj => {
        return (
          <Link to={obj.linkTo} key={obj.linkTo}>
            <Box minW={['120px', '250px', '250px', '300px', '350px']} textAlign="center" ml="auto" mr="auto" mb={6}>
              <Img fluid={obj.image.childImageSharp.fluid}/>
              <Box fontWeight="bold" mt={2}>{obj.title}</Box>
            </Box>
          </Link>
        )
      })}
    </Flex>
  )
};

export default NavExplorer;